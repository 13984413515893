import { IUmbracoError404IncomeData, IUmbracoError404ParsedData } from './models';

const defaultData: IUmbracoError404ParsedData = {
  title: '',
  text: '',
  errorText: '',
  image: {
    fallbackUrl: '',
    gatsbyImage: null,
  },
  imageAlt: '',
  links: [],
};

const parseUmbracoError404PageIncomeData = (
  param: IUmbracoError404IncomeData
): IUmbracoError404ParsedData => {
  const data = param.umbracoError404;
  const result: IUmbracoError404ParsedData = {
    title: data.page404Title || defaultData.title,
    text: data.page404Text || defaultData.text,
    errorText: data.page404ErrorText || defaultData.errorText,
    image: data?.page404Image || defaultData.image,
    imageAlt: data?.page404ImageAlt || defaultData.imageAlt,
    links: data.page404Links || defaultData.links,
  };

  return result;
};

export default parseUmbracoError404PageIncomeData;

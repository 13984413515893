import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import Layout from 'components/Layout';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';

import './Error404Page.scss';
import { IUmbracoError404IncomeData, IUmbracoError404ParsedData } from './models';
import parseUmbracoError404PageIncomeData from './parsers';

interface IPropsError404Page {
  data: IUmbracoError404IncomeData;
}

const Error404Page: FC<IPropsError404Page> = ({ data }) => {
  const {
    title,
    text,
    image,
    imageAlt,
    links,
    errorText,
  }: IUmbracoError404ParsedData = parseUmbracoError404PageIncomeData(data);

  const {
    umbracoError404: {
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      ogImage,
      lang,
    },
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  } = data;

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        ogImage,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
    >
      <section className="nf-error-page">
        <div className="nf-error-page__image">
          <UniversalImage imageAlt={imageAlt} img={image} />
        </div>
        <strong className="nf-error-page__title">{title}</strong>
        <DangerouslySetInnerHtml className="nf-error-page__text" html={text} />
        <ul className="nf-error-page__list">
          {links.map((item: UmbracoTypes.ILinkData) => (
            <li key={item.name}>
              <Link to={item.url}>{item.name}</Link>
            </li>
          ))}
        </ul>
        <p className="nf-error-page__text-uppercase">{errorText}</p>
      </section>
    </Layout>
  );
};

export const query = graphql`
  fragment FragmentError404Page on UmbracoError404 {
    ...FragmentSeo
    lang
    page404Text
    page404Title
    page404ErrorText
    page404Image {
      fallbackUrl
      url
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      svg {
        content
      }
    }
    page404ImageAlt
    page404Links {
      name
      url
    }
  }

  query($lang: String!, $pageIdRegex: String) {
    umbracoError404(lang: { eq: $lang }) {
      ...FragmentSeo
      lang
      page404Text
      page404Title
      page404ErrorText
      page404Image {
        fallbackUrl
        url
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        svg {
          content
        }
      }
      page404ImageAlt
      page404Links {
        name
        url
      }
    }
    pageLangs: allUmbracoError404(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default Error404Page;
